<template>
  <div class="main" @click="isShow = false">
    <header>
      <div class="banner relative">
        <img
          src="~/static/column/activity/banner.png"
          alt="逐梦人生绽放风采全国残疾人就业创业故事有奖征文"
          title="逐梦人生绽放风采全国残疾人就业创业故事有奖征文"
        />
        <img v-if="isKol == 0"
          src="~/static/column/activity/btn.png"
          alt="申请成为KOL作者"
          class="btn cursorP"
          @click="openDialog()"
        />
          <img v-else
          src="~/static/column/activity/kolPublish.png"
          alt="发布KOL文章"
          class="btn cursorP"
          @click="koldialogVisible = true"
        />
      </div>
      <div class="nav">
        <ul class="list">
          <li :class="active == 0 ? 'active' : ''" @click="tab(0)">
            <a href="#">首页</a>
          </li>
          <li :class="active == 1 ? 'active' : ''" @click="tab(1)">
            <a href="#one">活动介绍</a>
          </li>
          <li :class="active == 2 ? 'active' : ''" @click="tab(2)">
            <a href="#two">评奖标准</a>
          </li>
          <li :class="active == 3 ? 'active' : ''" @click="tab(3)">
            <a href="#three">评奖时间</a>
          </li>
          <li :class="active == 4 ? 'active' : ''" @click="tab(4)">
            <a href="#four">最新文章</a>
          </li>
          <li :class="active == 5 ? 'active' : ''" @click="tab(5)">
            <a href="#five">活动平台</a>
          </li>
        </ul>
      </div>
    </header>
    <div class="content">
      <section class="one" id="one">
        <div class="title">
          <img src="~/static/column/activity/1.png" alt="" />
        </div>
        <div class="box">
          <div class="info first">
            <h3>
              <img
                src="~/static/column/activity/fang.png"
                alt=""
              />征稿活动主题<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>逐梦人生 绽放风采</p>
            <p>全国残疾人就业创业故事有奖征文</p>
          </div>
          <div class="info">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />活动时间<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>长期，每季度评选1次</p>
          </div>
          <div class="info">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />征稿方向<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>残疾人就业、创业相关内容</p>
          </div>
          <div class="info">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />投稿方式<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>
              点击页面：我要做KOL专栏作者，申请开通您的KOL专栏账号，开启专栏发布权限。
            </p>
            <p>申请格式：名字+联系方式手机号，个人自我介绍。</p>
            <p>专栏作者权限审核时间：1-2个工作日。</p>
            <p>
              审核通过后，在兰州市残疾人就业创业网络服务平台，登录您的KOL账号，点击KOL专栏，发布您的专栏稿件。
            </p>
            <p>专栏文章审核时间：1-3个工作日。</p>
          </div>
          <div class="info">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />内容要求<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>
              来稿请附独创性声明，即来稿为作者独立进行创作的成果，未公开发表过；
            </p>
            <p>如有发生侵犯他人著作权的问题，其后果作者自负。</p>
            <p>
              本平台有权对采用稿件进行删节、修改。如有异议，请在投稿时声明。
            </p>
          </div>
        </div>
      </section>
      <section class="two" id="two">
        <div class="title">
          <img src="~/static/column/activity/2.png" alt="" />
        </div>
        <div class="box">
          <div class="info first">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />评分标准<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>
              以平台专栏文章阅读量、评论量、点赞量等数量进行综合评分，其中阅读占比50%，评论占比20%，点赞占比30%；
            </p>
            <h4>最终得分=总阅读量*50%+总评论量*20%+总点赞量*30%</h4>
          </div>
          <div class="info">
            <h3>
              <img
                src="~/static/column/activity/fang.png"
                alt=""
              />评分排行榜展示榜单<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <h5>图文季度榜（文章综合评分高低）</h5>
            <h5>图文年度榜（文章综合评分高低）</h5>
            <h5>作者排行榜（发布文章数量）</h5>
          </div>
          <div class="info">
            <h3>
              <img
                src="~/static/column/activity/fang.png"
                alt=""
              />图文季度奖项，实物奖励<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>每季度取 <span>图文季度榜</span> 前17名，颁发一次实物奖励：</p>
            <p>一等奖：2名（总分第1名至第2名）：奖励300元京东卡（总额600元）</p>
            <p>
              二等奖：5名（总分第3名至第7名）：奖励200元京东卡（总额1000元）
            </p>
            <p>
              三等奖：10名（总分第8名至第17名）：奖励100元京东卡（总额1000元）
            </p>
          </div>
          <div class="info">
            <h3>
              <img
                src="~/static/column/activity/fang.png"
                alt=""
              />图文及作者荣誉奖励<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>1. 获奖文章将在兰州市残疾人就业创业网络服务平台全渠道发布。</p>
            <p>2. 每年颁发一次荣誉奖励：</p>
            <p>
              文章荣誉：每年根据
              <span>图文年度榜</span>
              前15名，评审出年度优秀文章15篇，为每位优秀作者颁发“最佳KOL图文奖”奖牌，
            </p>
            <p>并优先邀请中奖人参与平台其他线下活动。</p>
            <p>
              作者荣誉奖励：每年根据
              <span>作者排行榜前5名</span>
              （每位作者发文数量不低于3篇），评选出“优秀KOL专栏作者奖”，颁发荣誉奖牌，
            </p>
            <p>
              并优先邀请中奖人参与平台其他线下活动，若符合要求的作者人数较少，则奖项轮空。
            </p>
          </div>
          <div class="info">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />中奖规则<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>
              1.
              每季度颁发一次实物奖励，根据KOL文章综合评分排名，由高到低排序，依次选取获奖名次
            </p>
            <p>
              （其中，参选文章阅读量不得低于100次，低于100次取消本次中奖资格）；
            </p>
            <div class="red">
              2.
              参赛者务必遵守大赛规则，浏览量、评论量、点赞量应真实有效，严禁恶意刷数据，
            </div>
            <div class="red">一经发现主办方有权取消该用户参赛资格。</div>
          </div>
        </div>
      </section>
      <section class="three" id="three">
        <div class="title">
          <img src="~/static/column/activity/3.png" alt="" />
        </div>
        <div class="box">
          <div class="info first">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />评奖时间<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>4月中旬：评选1-3月份实物奖励获奖用户</p>
            <p>7月中旬：评选4-6月份实物奖励获奖用户</p>
            <p>10月中旬：评选7-9月份实物奖励获奖用户</p>
            <p>
              1月中旬：评选10-12月份实物奖励获奖用户、评选本年度“最佳KOL图文奖”、“优秀KOL专栏作者奖”奖项。
            </p>
          </div>
          <div class="info">
            <h3>
              <img src="~/static/column/activity/fang.png" alt="" />公布平台<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <p>兰州市残疾人就业创业网络服务平台网站</p>
          </div>

          <div class="info">
            <h3>
              <img
                src="~/static/column/activity/fang.png"
                alt=""
              />关注微信公众号<img
                src="~/static/column/activity/fang.png"
                alt=""
              />
            </h3>
            <h5>服务号ID</h5>
            <p>兰州市残疾人就业创业网络服务平台</p>
            <h5>订阅号ID</h5>
            <p>中国残疾人就创业平台</p>
          </div>
        </div>
      </section>
      <section class="four" id="four">
        <div class="title">
          <img src="~/static/column/activity/4.png" alt="" />
        </div>
        <div class="box">
          <div class="left">
            <ul class="list">
              <li v-for="(item, index) in kolArticleList" :key="index">
                <div class="top flex cursorP" @click="goRouter('/section/sectionDetail',item.id)">
                  <h3>{{ item.name }}</h3>
                  <span v-show="item.hasTop == 1">置顶</span>
                </div>
                <div class="bottom">
                  <img :src="item.reamark1" alt="" class="banner" />
                  <div class="int relative">
                    <div class="text line_clamp2" v-html="item.gaidesc"></div>
                    <div class="status">
                      <span>
                        <img src="~/static/column/activity/look.png" alt="" />
                        {{item.clicks}}
                      </span>
                      <span>
                        <img
                          src="~/static/column/activity/pinglun.png"
                          alt=""
                        />
                        {{item.discussCount}}
                      </span>
                      <span @click.stop="collectCloumn(item.id, item)" :class="item.goods == 1 ? 'success' : ''">
                        <img
                          src="~/static/column/activity/zan1.png"
                          class="kol_img1"
                          v-show="item.goods == 1"
                        />
                        <img
                          src="~/static/column/activity/zan.png"
                          class="kol_img2"
                          alt=""
                          v-show="item.goods == 0"
                        />
                        {{item.supportCount}}
                      </span>
                      <span class="kol_share_span" @click.stop="isShow = item">
                        <div class="kol_share"></div>
                        分享
                      </span>
                    </div>
                    <div class="model" v-if="isShow == item">
                      <p
                        v-clipboard:copy="
                          'https://www.cdpee.org.cn/section/sectionDetail?id=' + item.id
                        "
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                      >
                        <img src="~/static/column/activity/copy.png" alt="" />
                        复制链接
                      </p>
                      <qrCode
                        :url="'https://www.cdpee.org.cn/section/sectionDetail?id=' + item.id"
                        width="114"
                        height="114"
                        class="qr_code"
                      ></qrCode>
                      <p>
                        <img src="~/static/column/activity/wechat.png" alt="" />
                        微信扫一扫
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <div class="titles">季度排行榜</div>
            <ul class="list">
              <li v-for="(item,index) in kolRankList" :key="index" class="line_clamp1 cursorP" @click="goRouter('/section/sectionDetail',item.id)">
                <span>{{index+1}}</span>
               {{item.name}}
              </li>
            </ul>
          </div>
          <el-button type="primary" class="btn" @click="goRouter('/section/activity/articleList')"
            >查看全部文章 <i class="el-icon-right"></i
          ></el-button>
        </div>
      </section>
      <section class="five" id="five">
        <div class="title">
          <img src="~/static/column/activity/5.png" alt="" />
        </div>
        <div class="contact">
          <div class="top">
            <div class="left">
              <p>扫码关注</p>
              <p>“兰州市残疾人就业创业网络服务平台”微信服务号</p>
              <p>及时获取评选及获奖信息</p>
              <img src="~/static/column/activity/qr1.png" alt="" />
            </div>
            <div class="right">
              <p>扫码关注</p>
              <p>扫码关注 “中国残疾人就创业平台”微信订阅号</p>
              <p>及时获取评选及获奖信息</p>
              <img src="~/static/column/activity/qr2.png" alt="" />
            </div>
          </div>
          <div class="bottom">
            <p>
              <span>主办单位：中国残疾人联合会就业服务指导中心</span>
              <span>活动平台：中国残疾人就创业网络服务平台/专栏版块</span>
            </p>
            <p>
              <span>技术支持：浙江浙大网新软件产业集团有限公司</span>
              <span>本活动解释权归主办方所有</span>
            </p>
          </div>
        </div>
      </section>
    <!-- 发布kol文章弹窗 -->
    <el-dialog
        :title="isKol?'文章发布':'KOL文章发布'"
        :visible.sync="koldialogVisible"
        width="580px"
        :before-close="handleClose">
      <articleForm :type="1" @close="handleClose"></articleForm>
    </el-dialog>
    </div>
    <div class="fixed" v-show="isHide" @click="openDialog()" v-if="isKol == 0">
      <img src="~/static/column/activity/fixed.png" alt="" />
    </div>
    <div class="fixed" v-show="isHide" @click="koldialogVisible = true" v-else>
      <img src="~/static/column/activity/kolPublish2.png" alt="" />
    </div>
    <!-- kol作者报名弹窗 -->
    <el-dialog
        title="KOL作者报名"
        :visible.sync="dialogVisible"
        width="580px"
        :before-close="handleClose">
      <kol-author-edit @close="handleClose"></kol-author-edit>
    </el-dialog>
    <login-prompt
      :goShow="isLogindialog"
      @close="handleClose"
      title="登录"
      :text="title"
    ></login-prompt>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";
import edit from "@/components/public/editor2"
import kolAuthorEdit from "@/components/community/kolAuthorEdit";
import articleForm from "@/components/community/articleForm";
export default {
  name: "index",
  components: { loginPrompt,edit,kolAuthorEdit,articleForm },
  data() {
    return {
      isKol:0,
      active: 0,
      isHide: false,
      isShow: "",
      title: "登录之后才可以查看",
      isLogin: "",
      isLogindialog: false,
      dialogVisible: false,
      koldialogVisible: false,
      form: {},
      kolArticleList: "",
      kolRankList: '',
      err: {
        picUrl: [
          { required: false, message: "头像不能为空", trigger: "change" },
        ],
        name: [{ required: true, message: "姓名不能为空", trigger: "change" }],
        sex: [{ required: true, message: "性别不能为空", trigger: "change" }],
        contentDesc: [
          { required: true, message: "自我介绍不能为空", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    info() {
      this.isLogin =
        this.$store.state.userInfo ||
        JSON.parse(localStorage.getItem("userInfo"));
      this.$api.kolgetKOLArticleSort().then((res)=>{
        if(res.data.success){
          this.kolRankList = res.data.data;
        }
      });
      this.kolAuthor();
    },
    tab(i) {
      this.active = i;
    },
    goRouter(url,id){
      const { href } = this.$router.resolve({
      path: url,
      query:{
        id:id
      }
      });
      window.open(href, "_blank");

    },
    //判断是否是KOL作者
    async kolAuthor() {
      let res = await this.$api.ifNotArticleApi();
      if (res.data.success) {
        this.isKol = res.data.data;
      }
    },
    // 展示弹窗
    openDialog() {
      if (this.isLogin) {
        if (this.isLogin.userType == 1) {
          this.dialogVisible = true;
        } else {
          this.$message.error("仅限残疾人申请成为KOL作者！");
        }
      } else {
        this.isLogindialog = true;
        this.$message.error = "请登录后申请成为KOL作者！";
      }
    },
    // 发布kol文章
    kolsaveArticle() {
      this.$api.kolsaveArticle(this.form).then((res) => {
        if (res.data.success) {
          this.$message.success(res.data.msg)
          this.info();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg)
        }
        console.log(res.data)
      })
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 500) {
        this.isHide = true;
      } else {
        this.isHide = false;
      }
    },
    onCopy: function (e) {
      this.$message.success("复制成功！");
    },
    onError: function (e) {
      this.$message.error("Failed to copy texts");
    },
    handleClose() {
      this.dialogVisible = false;
      this.isLogindialog = false;
      this.koldialogVisible = false;
    },
    handleAvatarimageurl(res, file) {
      this.form.picUrl = res.msg;
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true,
      });
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      console.log(isImage, isLt2M);
      return isImage && isLt2M;
    },
    //获取富文本的内容
    hanContent(val) {
      this.form.content = val;
    },
    // 文章列表
    getArticleList() {
      this.$api
        .kolgetArticleList({ fenclass: 5 ,pageNum: 1, pageSize: 6})
        .then((res) => {
          if (res.data.success) {
            this.kolArticleList = res.data.data.records;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // kol作者申请
    kolauthorKOLRegister() {
      this.$api.kolauthorKOLRegister(this.form).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
      //点赞
    collectCloumn(id, info) {
      if (this.isLogin) {
        this.$api.collectCloumnApi({ id: id }).then((res) => {
          info.goods = 1 ^ info.goods;
          this.getArticleList();
          if (info.goods) {
            info.supportCount += 1;
            this.$message.success(res.data.msg);
          } else {
            info.supportCount -= 1;
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("登录成功后才可以点赞！");
        this.isLogindialog = true;
      }
    },
  },
  created() {
    this.getArticleList();
    this.info();
  },
  mounted() {
    this.$emit("goRouter", "/section/");
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="less">
.main {
  header {
    .banner {
      img {
        width: 100%;

        display: block;
      }

      .btn {
        position: absolute;
        width: 263px;
        bottom: 126px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .nav {
      background: linear-gradient(0deg, #e04e42 0%, #da4538 50%, #ef584c 100%);
      border-top: 2px solid #ffc691;
      border-bottom: 2px solid #ffc691;

      .list {
        display: flex;
        width: 1200px;
        height: 96px;
        margin: 0 auto;

        li {
          display: flex;
          width: 200px;
          height: 100%;
          justify-content: center;
          align-items: center;

          a {
            color: #ffc1bd;
            font-size: 30px;
          }
        }

        li.active {
          background: #ffc691;

          a {
            font-size: 34px;
            font-weight: 600;
            color: #bf6c20;
          }
        }
      }
    }
  }

  .content {
    background-image: url("~static/column/activity/bg.png");

    section {
      padding-top: 95px;

      .title {
        text-align: center;
        margin-bottom: 25px;
      }

      .box {
        width: 1153px;
        margin: 0 auto;

        .info {
          padding-top: 37px;
          margin: 0 25px;
          text-align: center;

          h3 {
            font-size: 30px;
            color: #bf2c20;
            line-height: 34px;
            margin-bottom: 15px;

            img {
              vertical-align: middle;
            }

            img:first-child {
              margin-right: 12px;
            }

            img:last-child {
              margin-left: 12px;
            }
          }

          p {
            line-height: 32px;
            font-size: 18px;
            color: #1f271b;
          }
        }

        .first {
          padding-top: 72px;
        }
      }
    }

    .one {
      background-image: url("~static/column/activity/wz-hdjs.png");
      background-repeat: no-repeat;
      background-position: 0 0;

      .box {
        height: 906px;
        background: url("~static/column/activity/bg-hdjs.png") center top
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .two {
      background-image: url("~static/column/activity/wz-pjbz.png");
      background-repeat: no-repeat;
      background-position: 0 110px;

      .box {
        height: 1188px;
        background: url("~static/column/activity/bg-pfbz.png") center top
          no-repeat;
        background-size: 100% 100%;

        h4 {
          font-size: 24px;
          color: #1f271b;
          margin-top: 25px;
        }

        h5 {
          line-height: 32px;
          font-size: 18px;
          color: #1f271b;
        }

        p {
          span {
            font-size: 20px;
            font-weight: 600;
          }
        }

        .red {
          line-height: 32px;
          font-size: 18px;
          color: #c02d20;
        }
      }
    }

    .three {
      background-image: url("~static/column/activity/wz-pjsj.png");
      background-repeat: no-repeat;
      background-position: 0 125px;

      .box {
        height: 716px;
        background: url("~static/column/activity/bg-pjsj.png") center top
          no-repeat;
        background-size: 100% 100%;

        .info {
          h5 {
            font-size: 18px;
            color: #1f271b;
            margin-top: 38px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .four {
      background-image: url("~static/column/activity/wz-zxwz.png");
      background-repeat: no-repeat;
      background-position: 0 125px;

      .box {
        position: relative;
        display: flex;
        height: 1186px;
        background: url("~static/column/activity/bg-zxwz.png") center top
          no-repeat;
        background-size: 100% 100%;
        padding: 30px;

        .left {
          // flex: 6;
          width: 660px;
          // padding-right: 44px;

          .list {
            padding-left: 10px;

            li {
              padding: 17px 0;
              border-bottom: 1px solid rgba(0, 18, 10, 0.05);

              .top {
                h3 {
                  font-size: 18px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #00120a;
                }

                span {
                  display: block;
                  width: 36px;
                  height: 18px;
                  color: white;
                  background: #fe776c;
                  border-radius: 3px;
                  text-align: center;
                  font-size: 12px;
                  line-height: 18px;
                  margin-left: 5px;
                  margin-top: 2px;
                }
              }

              .bottom {
                display: flex;
                padding-top: 14px;

                .banner {
                  width: 120px;
                  height: 90px;
                  border-radius: 6px;
                  margin-right: 18px;
                }

                .int {
                  width:522px;
                  .text {
                    color: #66716c;
                    margin: 0 10px 20px 0;
                  }

                  span {
                    display: inline-block;
                    margin-right: 25px;
                    color: #99a09d;

                    img {
                      vertical-align: middle;
                    }
                  }

                  span:nth-of-type(3) {
                    cursor: pointer;
                  }

                  span:nth-of-type(4) {
                    cursor: pointer;
                  }
                }

                .model {
                  position: absolute;
                  left: 87px;
                  width: 174px;
                  height: 222px;
                  background: url("~static/column/activity/bk.png") center top
                    no-repeat;
                  background-size: 100% 100%;
                  text-align: center;
                  padding: 28px 30px 20px 30px;
                  z-index: 88;

                  p {
                    font-size: 16px;
                    color: #66716c;
                    margin-bottom: 10px;

                    img {
                      vertical-align: middle;
                    }
                  }

                  p:nth-of-type(2) {
                    font-size: 14px;
                    color: #99a09d;
                    margin-top: 10px;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        .right {
          flex: 1;
          margin-left: 48px;
          //height: 82%;
          //background: white;
          //border-radius: 6px;
          .titles {
            padding: 0 18px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #00120a;
            height: 54px;
            line-height: 54px;
            background: linear-gradient(
              90deg,
              #ffddda 0%,
              rgba(254, 119, 108, 0) 100%
            );
            border-top-left-radius: 6px;
            margin-top: 18px;
            margin-bottom: 12px;
          }

          .list {
            li {
              padding: 0 18px;
              margin-bottom: 18px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #00120a;

              span {
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 4px;
                color: #66716c;
                background: rgba(0, 18, 10, 0.05);
                text-align: center;
                line-height: 30px;
              }
            }

            li:nth-of-type(1) span,
            li:nth-of-type(2) span {
              color: white;
              background: #e60020;
            }

            li:nth-of-type(3) span,
            li:nth-of-type(4) span,
            li:nth-of-type(5) span {
              color: white;
              background: #fe776c;
            }
          }
        }

        .btn {
          position: absolute;
          left: 50%;
          bottom: 60px;
          transform: translateX(-50%);
          display: block;
          width: 180px;
          height: 48px;
          margin: 60px auto 0 auto;
          background: linear-gradient(90deg, #32c484 0%, #20de8b 100%);
          border: none;
          font-size: 16px;

          &:hover {
            background: linear-gradient(90deg, #20de8b 0%, #32c484 100%);
          }
        }
      }
    }
  }

  .five {
    height: 942px;
    background-image: url("~static/column/activity/footer.png");
    background-repeat: no-repeat;
    background-position: 0 50px;

    .contact {
      width: 1200px;
      margin: 0 auto;
      padding-top: 30px;

      .top {
        display: flex;

        .left,
        .right {
          flex: 1;
          text-align: center;

          p {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 31px;
          }

          img {
            margin-top: 35px;
          }
        }
      }

      .bottom {
        text-align: center;
        margin-top: 83px;

        p {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 31px;
        }
      }
    }
  }

  .fixed {
    position: fixed;
    right: -1%;
    top: 70%;
    transform: translateY(-50%);

    img {
      cursor: pointer;
    }
  }
}
  .kol_img1,.kol_img2 {
    display: inline-block;
  }

.success {
  color: #FE776C!important;
}
/deep/.qr{
  overflow: hidden!important;
}
/deep/ .el-dialog {
  .avatar-uploader {
    position: relative;
    width: 178px;
    height: 178px;
    //border: 1px solid #ddd;
    background: #f9f9f9;
    border-radius: 6px;
    cursor: pointer;
    //overflow: hidden;
    .el-upload.el-upload--text {
      display: block !important;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .el-icon-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: #00924b;
    }
  }
  p {
    color: #666666;
    font-size: 12px;
  }
  .button {
    padding: 20px 0;
    text-align: center;
    .el-button {
      width: 180px;
    }
  }
}
.kol_share_span{
.kol_share{
        width: 24px;
        display: inline-block;
        height: 24px;
        background-image: url("~static/column/activity/share.png");
        background-size: 100% 24px;
        transform: translateY(8px);
    }
        &:hover{
          .kol_share{
          background-image: url("~static/column/activity/share1.png");
          }
  color: #FE776C!important;
        }
}
</style>
